import React, { useEffect, useState, forwardRef } from 'react';
import { Button, Dialog, Slide } from '@material-ui/core';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';
import randomize from 'randomatic';

import config from 'Config/firebase';
import axios from 'Services/axios';
import firebaseService from 'Services/firebaseService';
import GlobalButton from 'Shared/Button';

import CloseIcon from './assets/cross.svg';
import CheckIcon from './assets/check.svg';
import RefreshIcon from './assets/refresh.svg';
import { useStyles } from './style';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export function VerifyConfirmModal({ code, open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.verifyConfirmModalContainer}>
        <div>
          <img src={CheckIcon} alt="confirmed" className={classes.verifyConfirmModalImage} />
        </div>
        <div className={classes.formattedCode}>{code}</div>
        <div className={classes.verifyConfirmModalText}>
          The numbers were sent to the current owner's email. Have the owner confirm these numbers or refresh and start
          again.
        </div>
        <div className={classes.messageConfirmModalButton}>
          <GlobalButton handleClick={onClose} btnLabel="Done" background />
        </div>
      </div>
    </Dialog>
  );
}

export function VerifyModal({
  open,
  onClose,
  userEmail,
  showConfirm,
  setVerificationCode,
  ethAddr,
  tokenId,
  tokenName,
  contract,
}) {
  const classes = useStyles();

  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isSent, setSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    const newCode = randomize('0', 6).toString();
    const parsedCode = `${newCode.slice(0, 3)}-${newCode.slice(3, 6)}`;

    setCode(parsedCode);
  };

  const handleSend = async () => {
    const validationResult = await axios.post('/checkRecaptcha', { captchaToken });

    if (validationResult?.data?.status !== 'success') {
      return;
    }

    setSent(true);
    const from = userEmail;
    const subject = 'Verification Code';
    const body = code;

    firebaseService
      .sendMessageToOwner({ from, subject, body, ethAddr, tokenId, tokenName, contract })
      .then(({ data: result }) => {
        if (result.status === 'success') {
          setCaptchaToken(null);
          setSent(false);
          handleRefresh();
          setVerificationCode(code);
          showConfirm(true);
          onClose();
        } else {
          setErrorMsg(result.message);
          setCaptchaToken(null);
          setSent(false);
          handleRefresh();
        }
      })
      .catch(() => {
        setErrorMsg('User is not registered at Ownerfy');
        setCaptchaToken(null);
        setSent(false);
        handleRefresh();
      });
  };

  const verifyCaptcha = res => {
    setCaptchaToken(res);
  };

  const expireCaptcha = () => {
    setCaptchaToken(null);
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.verifyModalContainer}>
        <div className={classes.verifyModalHeader}>
          <div>Verify</div>
          <button className={classes.verifyCloseModalButton} onClick={onClose}>
            <img src={CloseIcon} alt="Not found" />
          </button>
        </div>

        <div className={classes.messageModalBody}>
          {errorMsg && <div className={classes.errorMessage}>{errorMsg}</div>}
          {isSent && <div className={classes.formattedCode}>{code}</div>}
          {!isSent && (
            <>
              <div className={classes.draftCode}>{code}</div>
              <div className={classes.captcha}>
                <ReCAPTCHA sitekey={config.captchaSiteKey} onChange={verifyCaptcha} onExpired={expireCaptcha} />
              </div>
              <div className={classes.notification}>
                These random numbers will be sent to the current owner of the NFT. Ask the owner for confirmation of
                these numbers as proof of ownership.
              </div>
            </>
          )}

          <div className={classes.verifyButtons}>
            {!isSent && (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  className={clsx(classes.actionButton, classes.contactButton)}
                  onClick={handleRefresh}
                >
                  <img src={RefreshIcon} alt="refresh" />
                  <div className={classes.actionButtonLabel}>Refresh</div>
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  className={clsx(classes.actionButton, classes.verifyButton)}
                  disabled={isSent}
                  onClick={handleSend}
                >
                  Send
                </Button>
              </>
            )}
            {isSent && (
              <Button
                fullWidth
                variant="contained"
                className={clsx(classes.actionButton, classes.contactButton)}
                disabled={isSent}
                onClick={handleSend}
              >
                Sent!
              </Button>
            )}
          </div>
          {isSent && (
            <div className={classes.notification}>
              These numbers will be sent to the owner of the NFT. Ask the owner for confirmation of these numbers as
              proof of ownership.
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { connect } from 'react-redux';
import bs58 from 'bs58';
import axios from 'axios';
import { get } from 'lodash';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { isEmpty } from 'lodash';

import config from 'Config/firebase';
import { getAlchemyNftMetadata } from 'Services/api';
import Container from 'Components/Container';
import CenteredSpinner from 'Components/CenteredSpinner';
import { NameAndAddress } from 'Components/Tokens/NameAndAddress';
import { SocialShareButton } from 'Components/SocialShareButton';
import { MoreButton } from 'Components/MoreButton';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';

import history from '../../history';
import { ContactModal, ContactConfirmModal } from './ContactModal';
import { VerifyModal, VerifyConfirmModal } from './VerifyModal';
import { setActiveModal, logoutUser } from 'Store/actions';

import { useStyles } from './style';

import ContactIcon from './assets/contact.svg';
import VerifyIcon from './assets/verify.svg';

function NftDetails({ tokenId, contractAddress, blockchain, user }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tokenInfo, setTokenInfo] = useState(null);
  const [pageContractAddress, setPageContractAddress] = useState(contractAddress);
  const [pageBlockchain, setPageBlockchain] = useState(blockchain || 'ethereum');
  const [itemId, setItemId] = useState(null);
  const [contactModalVisible, showContactModal] = useState(false);
  const [contactConfirmModalVisible, showContactConfirmModal] = useState(false);
  const [verifyModalVisible, showVerifyModal] = useState(false);
  const [verifyConfirmModalVisible, showVerifyConfirmModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState(0);

  const openLoginModal = () => {
    dispatch(
      setActiveModal('login', {
        loginSuccessRedirectUrl: window.location.pathname,
        signupSuccessRedirectUrl: window.location.pathname,
      }),
    );
  };

  useEffect(() => {
    if (!tokenInfo || !tokenInfo.name) {
      const getTokenInformation = async () => {
        let ownerfyResponse;
        let oldContract = config.rcNFTContract;
        let _contract = contractAddress;
        let _blockchain = blockchain || 'ethereum';
        let _tokenInfo = [];

        const isOldDB = contractAddress === oldContract;

        let tokenIdInt = tokenId;

        // This gets called when we come in through the /nft url so we can assume
        // it is an Ownerfy item
        if (!contractAddress) {
          // This gets the OpenSea token ID
          const bytes = bs58.decode(tokenId);
          const b58Hex = bytes.toString('hex');
          tokenIdInt = parseInt('0' + b58Hex, 16);
          setItemId(tokenIdInt);

          if (tokenId.length > 6 || isOldDB) {
            // These are old format items and get pulled out of the old DB
            ownerfyResponse = await axios.get(`${config.functionsURL}/items/${tokenId}`);
          } else {
            // This is the new format and everything should go through here in the future
            // this is mapped to the /ipfs/:itemId endpoint in firebase.json
            // it needs to exist for external access to our system
            ownerfyResponse = await axios.get(`${config.ipfsLink}/${tokenId}`);
          }

          // This is if someone scans a printed NFT that has not been claimed so we need
          // to forward them to the claim page
          if (ownerfyResponse.data.claimed === false) {
            history.push(`/claim/${tokenId}`);
          }

          _contract = ownerfyResponse.data.contract || oldContract;
          _blockchain = ownerfyResponse.data.blockchain || 'ethereum';
          _tokenInfo = { ...ownerfyResponse.data };
        }

        if (_contract && tokenIdInt) {
          // If contract is supplied in the parameters assume this is a minted NFT
          try {
            const alchemyData = await getAlchemyNftMetadata({
              contract: _contract,
              blockchain: _blockchain,
              tokenId: tokenIdInt,
            });

            _tokenInfo = { ..._tokenInfo, ...alchemyData };
            if (_tokenInfo.media && _tokenInfo.media[0] && _tokenInfo.media[0].gateway) {
              _tokenInfo.image = _tokenInfo.media[0].gateway;
            }
          } catch (err) {
            console.warn('Error from alchemy response: ', err);
            return;
          }
        }

        setTokenInfo(_tokenInfo);
        setPageContractAddress(_contract);
        setPageBlockchain(_blockchain);

        setTimeout(function () {
          const videoPlayer = document.getElementById('token-video');
          if (videoPlayer) {
            videoPlayer.play();
          }
        }, 1000);
      };

      getTokenInformation();
    }
  }, [blockchain, contractAddress, tokenId, tokenInfo]);

  const tokenCreatedAt = useMemo(
    () => (get(tokenInfo, 'createdAt') ? new Date(tokenInfo.createdAt).toDateString() : ''),
    [tokenInfo],
  );

  const socialShareMessage = 'Check out my NFT on Ownerfy';
  const nftPageUrl = window.location.href;

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <div>
          {tokenInfo ? (
            <div className={classes.detailsContainer}>
              <div className={classes.details}>
                <h4 className={classes.heading}>{tokenInfo.name || tokenInfo.title}</h4>
                {tokenInfo.animation_url ? (
                  <video id="token-video" className={classes.nftImg} controls autoPlay loop muted>
                    <source src={tokenInfo.animation_url} type="video/mp4" />
                    Your browser does not support playing videos.
                  </video>
                ) : (
                  <img src={tokenInfo.image} alt="nft" className={classes.nftImg} />
                )}

                {tokenInfo?.owners.length === 1 && (
                  <div className={classes.actionButtons}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={clsx(classes.actionButton, classes.contactButton)}
                      onClick={() => {
                        if (isEmpty(user)) {
                          openLoginModal();
                        } else {
                          showContactModal(true);
                        }
                      }}
                    >
                      <img src={ContactIcon} alt="contact" />
                      <div className={classes.actionButtonLabel}>Contact The Owner</div>
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      className={clsx(classes.actionButton, classes.verifyButton)}
                      onClick={() => {
                        if (isEmpty(user)) {
                          openLoginModal();
                        } else {
                          showVerifyModal(true);
                        }
                      }}
                    >
                      <img src={VerifyIcon} alt="verify" />
                      <div className={classes.actionButtonLabel}>Verify Ownership</div>
                    </Button>
                  </div>
                )}
              </div>
              <Container>
                <div className={classes.cardContainer}>
                  <div className={classes.detailsCard}>
                    <div className={classes.cardHeadingContainer}>
                      <h4 className={classes.cardHeading}>Description</h4>
                      <div>
                        <SocialShareButton socialShareMessage={socialShareMessage} nftPageUrl={nftPageUrl} />
                        {pageContractAddress && (
                          <MoreButton
                            contractAddress={pageContractAddress}
                            tokenId={tokenId}
                            itemId={itemId}
                            blockchain={pageBlockchain}
                          />
                        )}
                      </div>
                    </div>
                    <div className={classes.cardBody}>
                      <p className={classes.cardDesc}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{tokenInfo.description}</ReactMarkdown>
                      </p>
                    </div>
                    {tokenInfo?.metadata?.attributes?.length > 0 && (
                      <div>
                        <div className={classes.cardHeadingContainer}>
                          <h4 className={classes.cardHeading}>Attributes</h4>
                        </div>
                        {tokenInfo.metadata.attributes.map(attribute => (
                          <div className={classes.infoContainer}>
                            <h5 className={classes.infoText}>{attribute.trait_type}</h5>
                            <h5 className={classes.infoText}>{attribute.value}</h5>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={classes.detailsCard}>
                    <div className={classes.cardHeadingContainer}>
                      <h4 className={classes.cardHeading}>Token Info</h4>
                    </div>
                    <div className={classes.cardBody}>
                      <div className={classes.infoContainer}>
                        <h5 className={classes.infoText}>Token ID</h5>
                        <h5 className={classes.infoText}>{tokenId}</h5>
                      </div>

                      <div className={classes.infoContainer}>
                        <h5 className={classes.infoText}>Blockchain</h5>
                        <h5 className={classes.infoText}>
                          {pageBlockchain[0].toUpperCase() + pageBlockchain.slice(1)}
                        </h5>
                      </div>

                      <div className={classes.infoContainer}>
                        <h5 className={classes.infoText}>Smart Contract</h5>
                        <h5 className={classes.infoText}>
                          <NameAndAddress blockchain={pageBlockchain} address={pageContractAddress} />
                        </h5>
                      </div>

                      <div className={classes.infoContainer}>
                        <h5 className={classes.infoText}>Owned by</h5>
                        <h5 className={classes.infoText}>
                          {tokenInfo.owners
                            ? tokenInfo.owners.map(owner => (
                                <NameAndAddress name="" blockchain={pageBlockchain} address={owner} key={owner} />
                              ))
                            : 'Private Owner'}
                        </h5>
                      </div>
                      {/* <div className={classes.infoContainer}>
                        <h5 className={classes.infoText}>Created by</h5>
                        <h5 className={classes.infoText}>
                          <NameAndAddress name={tokenInfo.creator} address={tokenInfo.creatorAddress} />
                        </h5>
                      </div>
                      <div className={classes.infoContainer}>
                        <h5 className={classes.infoText}>Created</h5>
                        <h5 className={classes.infoText}>{tokenCreatedAt}</h5>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          ) : (
            <CenteredSpinner />
          )}
        </div>
      </SectionLayout>
      <ContactModal
        open={contactModalVisible}
        onClose={() => showContactModal(false)}
        ethAddr={tokenInfo?.owners[0] || ''}
        tokenId={tokenId}
        tokenName={tokenInfo?.name}
        contract={pageContractAddress}
        showConfirm={() => showContactConfirmModal(true)}
        user={user}
      />
      <ContactConfirmModal open={contactConfirmModalVisible} onClose={() => showContactConfirmModal(false)} />
      <VerifyModal
        open={verifyModalVisible}
        onClose={() => showVerifyModal(false)}
        userEmail={user?.email}
        ethAddr={tokenInfo?.owners[0] || ''}
        tokenId={tokenId}
        tokenName={tokenInfo?.name}
        contract={pageContractAddress}
        showConfirm={() => showVerifyConfirmModal(true)}
        setVerificationCode={setVerificationCode}
      />
      <VerifyConfirmModal
        open={verifyConfirmModalVisible}
        onClose={() => showVerifyConfirmModal(false)}
        code={verificationCode}
      />
    </PageLayout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    tokenId: ownProps.match.params.tokenId,
    contractAddress: ownProps.match.params.contractAddress,
    blockchain: ownProps.match.params.blockchain,
  };
}

export default withRouter(connect(mapStateToProps, null)(NftDetails));

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  detailsContainer: {},
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '80px',
    marginTop: '-60px',
    backgroundColor: '#1E3545',
  },
  heading: {
    color: '#fff',
    fontFamily: 'Lato',
    fontSize: '36px',
    fontWeight: 'bold',
  },
  actionButtons: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    width: '500px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    border: 'none',
    borderRadius: '8px',
    height: '48px',
    color: 'white !important',
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
  },
  contactButton: {
    backgroundColor: '#21D7BB',

    '&:hover': {
      backgroundColor: '#01B79B !important',
    },
  },
  verifyButton: {
    backgroundColor: '#476DDC',

    '&:hover': {
      backgroundColor: '#274DBC !important',
    },
  },
  actionButtonLabel: {
    marginLeft: '12px',
    whiteSpace: 'nowrap',
  },
  nftImg: {
    maxWidth: '500px',
    maxHeight: '500px',
    borderRadius: '16px',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '40px',
    '@media (max-width: 800px)': {
      display: 'block',
    },
  },
  detailsCard: {
    flexBasis: '48%',
    minHeight: '435px',
    background: '#FFFFFF',
    borderRadius: '20px',
    padding: '60px',
    boxSizing: 'border-box',
    marginBottom: '20px',
  },
  cardHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #F2F2F2',
    paddingBottom: '20px',
  },
  cardHeading: {
    color: '#323441',
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 'bold',
    margin: '0',
  },
  cardBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
  cardDesc: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: ' normal',
    fontSize: '18px',
    letterSpacing: '0.02em',
    color: ' #5B5D68',
    // whiteSpace: 'pre',
  },
  cardBody: {
    marginTop: '12px',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: ' normal',
    fontSize: '15px',
    letterSpacing: '0.02em',
    color: ' #5B5D68',
    whiteSpace: 'noWrap',
    textAlign: 'right',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& a': {
      textDecoration: 'none',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '9px',
  },
  formButton: {
    width: '100%',
    height: '48px',
    fontWeight: 700,
    fontSize: 16,
    background: '#21D7BB',

    '&:disabled': {
      background: '#F3F3F3',
    },
  },
  captcha: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  messageModalContainer: {
    padding: '30px 30px 20px',
    minWidth: '426px',
  },
  messageModalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '28px',
    color: '#142B3A',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    gap: '30px',
  },
  messageModalDesc: {
    marginBottom: '28px',
    color: '#142B3A',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: 'bold',
  },
  closeModalButton: {
    margiTop: '6px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: '10px !important',
  },
  messageConfirmModalContainer: {
    minWidth: '465px',
    padding: '66px 42px 64px',
    textAlign: 'center',
  },
  messageConfirmModalText: {
    marginTop: '50px',
    marginBottom: '45px',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Lato',
  },
  messageConfirmModalButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '153px',
    height: '57px',
  },
  errorMessage: {
    fontFamily: 'Lato',
    fontSize: '16px',
    color: '#ff3333',
    marginTop: '6px',
  },
  verifyModalContainer: {
    padding: '30px 30px 32px',
    width: '426px',
  },
  verifyModalHeader: {
    position: 'relative',
    marginBottom: '28px',
    height: '54px',
    textAlign: 'center',
    color: '#142B3A',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: 'bold',
  },
  verifyCloseModalButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margiTop: '6px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: '10px !important',
  },
  verifyButtons: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
  },
  draftCode: {
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '14px 18px',
    height: 50,
    backgroundColor: '#F3F3F3',
    textAlign: 'center',
    color: '#142B3A',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,
  },
  notification: {
    marginTop: '15px',
    color: '#748896',
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Lato',
  },
  formattedCode: {
    color: '#476DDC',
    textAlign: 'center',
    fontSize: '48px',
    fontFamily: 'Lato',
  },
  verifyConfirmModalContainer: {
    boxSizing: 'border-box',
    width: '465px',
    padding: '50px 40px',
    textAlign: 'center',
  },
  verifyConfirmModalImage: {
    width: '65px',
  },
  verifyConfirmModalText: {
    marginTop: '16px',
    marginBottom: '26px',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Lato',
  },
}));

export { useStyles };
